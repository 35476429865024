import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const mainRouter = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeLight />} />
          <Route element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default mainRouter;
